import classNames from 'classnames'
import React, {CSSProperties, KeyboardEvent} from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {isItemOpened} from '../../selectors/layout'
import {useWidgetActions, useWidgetState} from '../../hooks/state-provider'
import {getComponentConfig, isListLayout} from '../../selectors/settings'
import {isAdditionalComponentsHidden} from '../../selectors/list-settings'
import {EventContainerProps} from './interfaces'
import s from './event-container.scss'

export const EventContainer = ({event, mobile, dividerWidth, stripWidth, lastItem, children}: EventContainerProps) => {
  const [hovered, setHovered] = React.useState(false)
  const fixNavigateToPageEnabled = useExperiments().experiments.enabled(ExperimentNames.FixNavigateToPage)

  const opened = useWidgetState(state => isItemOpened(state, event.id))
  const listLayout = useWidgetState(state => isListLayout(getComponentConfig(state)))
  const additionalComponentsHidden = useWidgetState(state => isAdditionalComponentsHidden(state, event.id))
  const {openListLayoutItem, closeListLayoutItems} = useWidgetActions()

  const onKeyPress = (e: KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter') {
      toggle(e)
    }
  }

  const onMouseEnter = () => {
    setHovered(true)
  }

  const onMouseLeave = () => {
    setHovered(false)
  }

  const toggle = e => {
    if (fixNavigateToPageEnabled && e.target.getAttribute('data-hook') === 'ev-rsvp-button') {
      return
    }
    if (opened) {
      closeListLayoutItems()
    } else if (!additionalComponentsHidden) {
      openListLayoutItem(event.id)
    }
    e.stopPropagation()
  }

  const getContainerStyle = (): CSSProperties => {
    const padding = `${stripWidth + (mobile ? 0 : 10)}px 0`

    if (hovered || opened) {
      return {
        padding,
        marginTop: -dividerWidth,
        borderBottomWidth: dividerWidth,
        borderTopWidth: dividerWidth,
      }
    }

    return {
      padding,
      borderBottomWidth: dividerWidth,
      borderBottomColor: lastItem ? 'transparent' : undefined,
    }
  }

  return (
    <li
      style={getContainerStyle()}
      onKeyPress={onKeyPress}
      data-hook={DH.listItem}
      className={classNames(s.container, mobile && !listLayout ? s.cardBorderColor : s.listDividerColor, {
        [s.listHoveredBorderColor]: listLayout && (hovered || opened),
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={toggle}
    >
      {children(hovered)}
    </li>
  )
}
