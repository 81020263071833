import classNames from 'classnames'
import React, {useEffect} from 'react'
import {useFedopsLogger, useExperiments} from '@wix/yoshi-flow-editor'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {LoadMoreButton} from '../load-more-button'
import {MainListTitle} from '../main-list-title'
import {EventContainer} from '../event-container'
import {useWidgetState} from '../../hooks/state-provider'
import {getComponentConfig, getListDividerWidth, getListStripWidth} from '../../selectors/settings'
import s from './list-layout.scss'
import {ItemContainer as Item} from './item-container'
import {DesktopItem} from './item'
import {ListLayoutProps} from '.'

export const ListLayout = ({events, t, narrow, mobile, listLayout}: ListLayoutProps) => {
  const newMobileWidgetLayouts = useExperiments().experiments.enabled(ExperimentNames.NewMobileWidgetLayouts)

  const style: React.CSSProperties = {
    padding: narrow ? '0 20px' : '0 30px',
    overflowY: narrow && !mobile ? 'scroll' : 'hidden',
    height: narrow && !mobile ? '100%' : 'auto',
  }
  const className = classNames(s.container, s.scrollBarColor, mobile && !listLayout ? s.cardContainer : s.listContainer)

  const componentConfig = useWidgetState(getComponentConfig)
  const dividerWidth = getListDividerWidth(componentConfig)
  const stripWidth = getListStripWidth(componentConfig, false)

  const fedopsLogger = useFedopsLogger()

  useEffect(() => {
    if (narrow && !mobile) {
      fedopsLogger.interactionStarted('narrow-layout')
      fedopsLogger.interactionEnded('narrow-layout')
    }
  }, [narrow, mobile])

  return (
    <div className={className} style={style} data-hook="ev-list-layout">
      <MainListTitle />
      <ul>
        {events.map((event: ExtendedEvent, index: number) =>
          newMobileWidgetLayouts ? (
            <EventContainer
              key={event.id}
              event={event}
              lastItem={events.length - 1 === index}
              mobile
              dividerWidth={dividerWidth}
              stripWidth={stripWidth}
            >
              {hovered => <DesktopItem event={event} hovered={hovered} t={t} />}
            </EventContainer>
          ) : (
            <Item key={event.id} event={event} t={t} lastItem={events.length - 1 === index} />
          ),
        )}
      </ul>
      <LoadMoreButton />
    </div>
  )
}
